import { useState } from "react";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 1000,
});
const RegesterPage = ({ authToken, clientId }) => {
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlRegister = async (e) => {
    e.preventDefault();
    console.log(authToken, clientId);
    setFormErrors(validateForm());
    try {
      const response = await api.post(
        "auths/register",
        {
          clientId,
          fullName,
          mobile,
          username,
          password,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSuccess(response.data);
      setError("");
    } catch (error) {
      //console.error("reg failed:", error);
      setError(error.message);
      setSuccess("");
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!fullName) {
      errors.fullName = "Name is required.";
    }
    if (!mobile) {
      errors.mobile = "mobile is required.";
    }
    if (!username) {
      errors.username = "username is required.";
    }
    if (!password) {
      errors.password = "Password is required.";
    }
    return errors;
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="shadow p-3 mb-5 bg-body rounded">
          <h1>Register Page</h1>
          <div className="">
            <form onSubmit={handlRegister}>
              <div className="form-group">
                <label className="form-label" htmlFor="fullname">
                  Full Name:
                </label>
                <input
                  required
                  placeholder="Enter your name"
                  className="form-control"
                  type="text"
                  id="fullname"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {formErrors.fullName && <p>{formErrors.fullName}</p>}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="password">
                  Mobile:
                </label>
                <input
                  required
                  className="form-control"
                  type="mobile"
                  id="mobile"
                  placeholder="Enter your mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {formErrors.mobile && (
                  <p className="invalid-feedback">{formErrors.mobile}</p>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="username">
                  Username:
                </label>
                <input
                  required
                  placeholder="Enter username"
                  className="form-control"
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="password">
                  Password:
                </label>
                <input
                  required
                  className="form-control"
                  type="password"
                  id="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </form>
          </div>
          <br />
          {error && <p className="alert alert-danger">{error}</p>}
          {success && (
            <p className="alert alert-success">
              <p className="fw-semibold"> User registerd Successfully</p>
              <p className="fw-semibold">User Id: {success.user_id}</p>
              <p>{success.full_name}</p>
              <p>{success.user_mobile}</p>
              <p>{success.user_name}</p>
            </p>
          )}
          <button
            type="submit"
            className="btn btn-success"
            onClick={handlRegister}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegesterPage;
