import React, { useEffect, useState } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CourierForm from "../components/CourierForm";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Couriers = ({ authToken }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [couriersData, setCouriersData] = useState([]);
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [formData, setFormData] = useState({
    id: "",
    client_id: "",
    courierName: "",
    courierApi: "",
    apiSecret: "",
    account: "",
  });

  useEffect(() => {
    api
      .get("couriers/all", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setCouriersData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const resetForm = () => {
    setFormData({
      id: "",
      client_id: "",
      courierName: "",
      courierApi: "",
      apiSecret: "",
      account: "",
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const viewCourier = () => {
    setViewSet(!viewSet);
    setIsEditMode(false);
    resetForm();
    if (!couriersData || couriersData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new courier",
        severity: "warning",
      });
    }
  };

  const handleEdit = async (id) => {
    setViewSet((prevViewSet) => !prevViewSet);
    setIsEditMode(true);
    const courier = couriersData.filter((courier) => courier.id === id);
    setFormData({
      id: id,
      courierName: courier[0].client_id,
      courierName: courier[0].name,
      courierApi: courier[0].api_key,
      apiSecret: courier[0].api_password,
      account: courier[0].account,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      setSnackbar({
        children: "updated",
        severity: "success",
      });
      setTimeout(() => {
        resetForm();
        window.location.reload();
      }, 3000);
    } else {
      try {
        const response = await api.post(`couriers/add`, formData, {
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
        });
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        resetForm();
      } catch (error) {
        console.error("Submit Failed:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    }
  };

  if (viewSet && couriersData.length > 0) {
    return (
      <div className="container mt-5">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewCourier}
        >
          <i className="bi bi-gear"></i> &nbsp; Add New Courier
        </button>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="d-flex  justify-content-center">
              <h4 className="fw-bold w-auto shadow-lg p-3 mb-3 rounded bg-body">
                Couriers
              </h4>
            </div>
            {couriersData.map((courier) => (
              <div
                key={courier.id}
                className=" shadow p-3 mb-3 bg-body rounded"
              >
                <div className=" d-flex flex-wrap">
                  <div className="col-sm-12 col-lg-2">
                    <h5 className=" shadow-sm p-2 bg-body rounded">
                      {courier.name}
                    </h5>
                  </div>

                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Status: {courier.status}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Date: {courier.created_at}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-2">
                    <p
                      className={
                        !courier.isActive
                          ? "btn btn-info"
                          : "shadow-sm bg-body rounded"
                      }
                    >
                      {!courier.isActive ? "Not Active" : "Active"}
                    </p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-2">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => handleEdit(courier.id)}
                    >
                      <i className="bi bi-pen"></i>&nbsp;Edit
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <CourierForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isEditMode={isEditMode}
        viewCourier={viewCourier}
      />
      {/* <div className="d-flex justify-content-center ">
        <div className="shadow-lg p-3 mb-5 bg-body rounded col-lg-6 ">
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary float-end"
            onClick={viewCourier}
          >
            <i className="bi bi-eye"></i> &nbsp; View Couriers
          </button>
          <br />
          <br />
          <p>Please enter all information correct it can not be undo</p>
          <h4 className="mb-5 text-center">Courier Register</h4>

          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="courierName" className="form-label">
                  Name Registerd Courier
                </label>
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="Courier Name"
                  name="courierName"
                  value={formData.courierName}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="courierApi" className="form-label">
                  courier Api:
                </label>
                <input
                  required
                  placeholder="courier api"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="courierApi"
                  value={formData.courierApi}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3 ">
                <label htmlFor="apiSecret" className="form-label">
                  {" "}
                  Api Secret:
                </label>

                <input
                  required
                  placeholder="courier api password"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiSecret"
                  value={formData.apiSecret}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="apiVersion" className="form-label">
                  {" "}
                  Api Version:
                </label>

                <input
                  required
                  placeholder="apiVersion..."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiVersion"
                  value={formData.apiVersion}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="locationId" className="form-label">
                  {" "}
                  location Id:
                </label>

                <input
                  required
                  placeholder="yourstore location inv"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="locationId"
                  value={formData.locationId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <button className=" btn btn-success" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Couriers;
