import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StoreForm from "../components/StoreForm";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const SourceStores = ({ authToken }) => {
  const navigate = useNavigate();
  const [storesData, setStoresData] = useState([]);
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const [formData, setFormData] = useState({
    id: "",
    client_id: "",
    shopName: "",
    publicName: "",
    shopifyDomain: "",
    storeApi: "",
    apiSecret: "",
    apiVersion: "",
    orderPrefix: "",
    locationId: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const viewStores = () => {
    setViewSet((prevViewSet) => !prevViewSet);
    setIsEditMode(false);
    resetForm();
    if (storesData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new store",
        severity: "warning",
      });
    }
  };

  useEffect(() => {
    api
      .get("stores/all", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setStoresData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const resetForm = () => {
    setFormData({
      id: "",
      client_id: "",
      shopName: "",
      publicName: "",
      shopifyDomain: "",
      storeApi: "",
      apiSecret: "",
      apiVersion: "",
      orderPrefix: "",
      locationId: "",
    });
  };
  const handleEdit = async (id) => {
    setViewSet((prevViewSet) => !prevViewSet);
    setIsEditMode(true);
    const store = storesData.filter((store) => store.id === id);

    setFormData({
      id: id,
      client_id: store[0].client_id,
      shopName: store[0].name,
      publicName: store[0].public_name,
      shopifyDomain: store[0].domain,
      storeApi: store[0].api_key,
      apiSecret: store[0].api_password,
      apiVersion: store[0].api_version,
      orderPrefix: store[0].order_prefix,
      locationId: store[0].location_id,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      try {
        const response = await api.put(`stores/update`, formData, {
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
        });
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setTimeout(() => {
          resetForm();
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error("Submit Failed:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    } else {
      try {
        const response = await api.post(`stores/add`, formData, {
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setTimeout(() => {
          resetForm();
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error("Submit Failed:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    }
  };

  const handleViewClick = (id) => {
    navigate(`/storesdetails/${id}`);
  };

  if (viewSet && storesData.length > 0) {
    return (
      <div className="container mt-5">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewStores}
        >
          <i className="bi bi-gear"></i> &nbsp; Add New Store
        </button>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="d-flex  justify-content-center">
              <h4 className="fw-bold w-auto shadow-lg p-3 mb-3 rounded bg-body">
                Stores
              </h4>
            </div>
            {storesData.map((store) => (
              <div key={store.id} className=" shadow p-3 mb-3 bg-body rounded">
                <div className="float-end">
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => handleEdit(store.id)}
                  >
                    <i className="bi bi-pen"></i>&nbsp;Edit
                  </button>

                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => handleViewClick(store.id)}
                  >
                    <i className="bi bi-eye"></i>&nbsp;View Details
                  </button>
                </div>
                <div className=" d-flex flex-wrap">
                  <div className="col-sm-12 col-lg-2">
                    <h5 className=" shadow-sm p-2 bg-body rounded">
                      {store.public_name}
                    </h5>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>{store.domain}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Status: {store.status}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Date: {store.created_at}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-2">
                    <p
                      className={
                        !store.isActive
                          ? "btn btn-info"
                          : "shadow-sm bg-body rounded"
                      }
                    >
                      {!store.isActive ? "Not Active" : "Active"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <StoreForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isEditMode={isEditMode}
        viewStores={viewStores}
      />
    </div>
  );
};

export default SourceStores;
