import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const StoreDetail = ({ authToken }) => {
  const { id } = useParams();
  const [storeData, setStoreData] = useState();
  const [ordersCount, setOrdersCount] = useState("0");
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const activeReq = async () => {
    if (window.confirm("Are You sure ")) {
      try {
        const response = await api.put(
          `stores/isactive/${id}`,
          { isActive: !storeData.isActive },
          {
            headers: {
              token: authToken,
            },
          }
        );
        setSnackbar({
          children: response.data,
          severity: "success",
        });
      } catch (error) {
        console.error("Error fetching store data:", error);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`stores/${id}`, {
          headers: {
            token: authToken,
          },
        });
        setStoreData(response.data);
        const resp = await api.get(`orders/ofstore/${id}`, {
          headers: {
            token: authToken,
          },
        });
        setOrdersCount(resp.data.length);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const sendApprovalRequest = async () => {
    try {
      const response = await api.get(`stores/approval/${id}`, {
        headers: {
          token: authToken,
        },
      });
      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setSnackbar({
          children: error.message,
          severity: "error",
        });
      } else {
        console.error("Error fetching store data:", error);
      }
      setLoading(false);
    }
  };
  const checkScopes = async () => {
    if (window.confirm("Are You sure you want to sync")) {
      setLoading(true);
      try {
        setSnackbar({
          children: "chekbox",
          severity: "success",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="d-flex flex-wrap justify-content-evenly">
        <div className="col-lg-5 col-xl-5 col-sm-10 shadow p-3 mb-5 bg-body rounded ">
          <div className="d-flex flex-column ">
            <div className="mt-5">
              <h6 className="">{storeData.name}</h6>
            </div>

            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              <p>Store Domain: {storeData.domain}</p>
              <p>Store Api: {storeData.api_key}</p>
              <p>Store Id: {storeData.id}</p>

              <p>Date: {storeData.created_at}</p>
            </div>
            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              {storeData.status === "Approved" ? (
                <div>
                  <h5>Get scopes</h5>
                  <button className="btn btn-info" onClick={checkScopes}>
                    Chek Scops
                  </button>
                </div>
              ) : (
                storeData.status
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-xl-4 col-sm-10  p-3 mb-5 ">
          <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column">
            <div>
              <p className="float-start fw-bold">Status</p>
              <p className="float-end fw-lighter">
                {storeData.status}
                <i
                  onClick={() => alert("i cliked")}
                  class="bi bi-three-dots-vertical float-end"
                ></i>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={activeReq}
                disabled={storeData.status !== "Approved"}
              >
                <i className="bi bi-gear "> </i>
                {storeData.isActive ? "deactivate" : "activate"}
              </button>

              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={sendApprovalRequest}
                disabled={storeData.status === "Approved"}
              >
                <i className="bi bi-gear "> </i>
                {storeData.status !== "Approved"
                  ? "Send Request "
                  : storeData.status}
              </button>
            </div>
          </div>

          <div className="shadow p-3 mb-3 bg-body rounded ">
            <div className="d-flex  flex-column">
              <div className="">
                <p className="fw-bold float-start">Insights</p>
                <p className="fw-lighter float-end">Last 90 Days</p>
              </div>
              <div>
                <p>Procesed {ordersCount} Orders Of this Store</p>
                <Link className="float-start">View Details</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
