import React from "react";

function StoreForm({
  formData,
  handleChange,
  handleSubmit,
  isEditMode,
  viewStores,
}) {
  return (
    <div className="d-flex justify-content-center ">
      <div className="shadow-lg p-3 mb-5 bg-body rounded col-lg-6">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewStores}
        >
          <i className="bi bi-eye"></i> &nbsp; View Stores
        </button>
        <br />
        <br />
        <p>
          Please enter all information correctly. It cannot be undone and it
          will consume your store limits.
        </p>
        <h4 className="mb-5 text-center">
          {isEditMode ? "Update Store" : "Register Store"}
        </h4>

        <div className="">
          <form onSubmit={handleSubmit}>
            <div className="mb-3 d-flex justify-content-around">
              <label htmlFor="shopName" className="form-label ml-2">
                Registerd Store Name
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="yourstore"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="publicName" className="form-label">
                Public Name
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="public name"
                  name="publicName"
                  value={formData.publicName}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="client_id" className="form-label">
                Domain client_id
              </label>

              <input
                required
                autoComplete="off"
                className="form-control"
                type="text"
                placeholder="client_id..."
                name="client_id"
                value={formData.client_id}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="shopifyDomain" className="form-label">
                Full Sopify Domain name
              </label>

              <input
                required
                autoComplete="off"
                className="form-control"
                type="text"
                placeholder="yourstore.myshopify.com"
                name="shopifyDomain"
                value={formData.shopifyDomain}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="storeApi" className="form-label">
                Store Api:
              </label>
              <input
                required
                placeholder="yourstore api"
                autoComplete="off"
                className="form-control"
                type="text"
                name="storeApi"
                value={formData.storeApi}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3 ">
              <label htmlFor="apiSecret" className="form-label">
                {" "}
                Api Secret:
              </label>

              <input
                required
                placeholder="yourstore api password"
                autoComplete="off"
                className="form-control"
                type="text"
                name="apiSecret"
                value={formData.apiSecret}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 ">
              <label htmlFor="apiVersion" className="form-label">
                {" "}
                Api Version:
              </label>

              <input
                required
                placeholder="apiVersion..."
                autoComplete="off"
                className="form-control"
                type="text"
                name="apiVersion"
                value={formData.apiVersion}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 ">
              <label htmlFor="orderPrefix" className="form-label">
                {" "}
                Order Prefix:
              </label>

              <input
                required
                placeholder="orderPrefix..."
                autoComplete="off"
                className="form-control"
                type="text"
                name="orderPrefix"
                value={formData.orderPrefix}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 ">
              <label htmlFor="locationId" className="form-label">
                {" "}
                location Id:
              </label>

              <input
                required
                placeholder="yourstore location inv"
                autoComplete="off"
                className="form-control"
                type="text"
                name="locationId"
                value={formData.locationId}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <button className=" btn btn-success" type="submit">
                {isEditMode ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StoreForm;
