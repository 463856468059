import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ClientIdContext = createContext();
export const useClientId = () => useContext(ClientIdContext);

export const ClientIdProvider = ({ children }) => {
  const [clientId, setClientId] = useState(null);
  const [subD, setSubD] = useState("");

  useEffect(() => {
    const extractSubdomain = () => {
      const parts = window.location.hostname.split(".");
      if (parts.length >= 2 && parts[1] === process.env.REACT_APP_HOST) {
        setSubD(parts);
        return parts[0];
      }
      return null;
    };

    const fetchClientId = async (sub) => {
      try {
        const response = await api.get(`/clients/find/${sub}`);
        setClientId(response.data);
      } catch (error) {
        console.error("Error fetching client ID:", error);
        setClientId(401);
      }
    };

    const sub = extractSubdomain();
    if (sub) {
      fetchClientId(sub);
    } else {
      console.error("Subdomain not found");
    }
  }, []);

  if (clientId === null) {
    return null; // or return a loading indicator
  }
  // console.log("this is from context", clientId);
  return (
    <ClientIdContext.Provider value={{ clientId: clientId, subD: subD }}>
      {children}
    </ClientIdContext.Provider>
  );
};
