import { useState } from "react";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const RegesterPage = ({ authToken }) => {
  const [domainName, setDomainName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlRegister = async (e) => {
    e.preventDefault();
    setFormErrors(validateForm());
    try {
      const response = await api.post(
        "clients/register",
        {
          domainName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
        }
      );

      setSuccess(response.data);
      setError("");
    } catch (error) {
      setError(error.message);
      setSuccess("");
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!domainName) {
      errors.domainName = "Name is required.";
    }
    return errors;
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="shadow p-3 mb-5 bg-body rounded">
          <h6>Register Subdomain</h6>
          <div className="">
            <form onSubmit={handlRegister}>
              <div className="form-group">
                <label className="form-label" htmlFor="domainName">
                  Domain Name:
                </label>
                <input
                  required
                  placeholder="Enter subdomain"
                  className="form-control"
                  type="text"
                  id="domainName"
                  value={domainName}
                  onChange={(e) => setDomainName(e.target.value)}
                />
                {formErrors.domainName && <p>{formErrors.domainName}</p>}
              </div>
            </form>
          </div>
          <br />
          {error && <p className="alert alert-danger">{error}</p>}
          {success && (
            <p className="alert alert-success">
              <p className="fw-semibold"> Domain registerd Successfully</p>

              <p>{success.name}</p>
            </p>
          )}
          <button
            type="submit"
            className="btn btn-success"
            onClick={handlRegister}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegesterPage;
