import axios from "axios";
import { loginFailure, loginStart, loginSuccess } from "./AuthActions";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
export const login = async (user, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await api.post("auths/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};
