import { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./authContext/AuthContext";
import { useClientId } from "./components/ClientIdContext";
import OrdersPay from "./pages/OrdersPay";
import Orders from "./pages/Orders";
import Stores from "./pages/SourceStores";
import StoresDetails from "./pages/StoreDetails";
import Couriers from "./pages/Couriers";
import AppBarComponent from "./components/AppBarComponent";
import LoginPage from "./pages/LoginPage";
import RegPage from "./pages/RegPage";
import Users from "./pages/Users";
import DomainRegPage from "./pages/DomainRegPage";

function App() {
  const { user } = useContext(AuthContext);
  const userToken = user?.accessToken;
  const { clientId, subD } = useClientId();
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppBarComponent authToken={userToken} />}>
            <Route
              path="/orders"
              element={<Orders authToken={userToken} />}
            ></Route>
            <Route
              path="/orders_pay"
              element={<OrdersPay authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route
              path="/stores"
              element={<Stores authToken={userToken} />}
            ></Route>
            <Route
              path="/couriers"
              element={<Couriers authToken={userToken} />}
            ></Route>
            <Route
              path="/register user"
              element={<RegPage authToken={userToken} clientId={clientId} />}
            ></Route>
            <Route
              path="/users"
              element={<Users authToken={userToken} />}
            ></Route>
            <Route
              path="/storesdetails/:id"
              element={<StoresDetails authToken={userToken} />}
            ></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/register-store"
            element={<DomainRegPage authToken={userToken} subD={subD} />}
          />
          <Route
            path="/register_user"
            element={<RegPage authToken={userToken} clientId={clientId} />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
