import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { login } from "../authContext/apiCalls";
import { AuthContext } from "../authContext/AuthContext";
import { useNavigate } from "react-router-dom";
// import Nav from "./Nav";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 1000,
});

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { dispatch } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Assuming your login function returns a promise
      await login({ username, password }, dispatch);

      // Redirect to the home page after successful login
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };
  // const handleLogin = async () => {
  //   try {
  //     const response = await api.post("auth/login", {
  //       username,
  //       password,
  //     });

  //     setSuccess(response.data.accessToken);
  //     const token = response.data.accessToken;
  //     console.log(response);
  //     // Call the parent component's onLogin function to update the authToken in the App component
  //     onLogin(token);
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //     setError(error.response.data);
  //   }
  // };

  return (
    <div
      className=""
      style={{
        backgroundColor: "rgba(255, 0, 0, 0.5)", // Semi-transparent red
      }}
    >
      {/* <Nav /> */}

      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="https://vnoirs.com/">
            Vnoirs
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Pricing
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="col-sm-10 col-lg-4 shadow p-3 mb-5 bg-body rounded">
          <p className="fw-semibold">Login Page</p>
          <div className="">
            <div className="form-group">
              <label className="form-label" htmlFor="username">
                Username:
              </label>
              <input
                className="form-control"
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <label className="form-label" htmlFor="password">
              Password:
            </label>
            <input
              className="form-control"
              type="password"
              id="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <br />
          {error && <p className="alert alert-danger">{error}</p>}
          {success && (
            <p className="alert alert-success">
              User loged in Successfuly With token: {success}
            </p>
          )}
          <button
            type="button"
            className="btn btn-success"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
