import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useClientId } from "../components/ClientIdContext";
import { AuthContext } from "../authContext/AuthContext";

export function useRedirectIfUnauthorized() {
  const navigate = useNavigate();
  const { clientId, subD } = useClientId();

  useEffect(() => {
    if (clientId === 401 || !clientId) {
      navigate("/login", { replace: true });
    }
  }, [clientId]);
}
export function useRedirectIfUnauthorizedUser() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }, [user]);
}
